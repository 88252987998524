$error-color: red;

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

label {
  font-family: $header-font;
  @include font-weight(bold);
  @include rem(font-size, 14px);
  display: block;
  padding: 10px 0 5px;
}

.error-message {
  color: primary-color(white);
  background: red;
  padding: 2px 4px;
  @include rem(font-size, 14px);
  @include font-weight(medium);
  margin-top: -15px;
  margin-bottom: 15px;
}

// Input Fields
//
// :focus      - Focus state
//
// Markup:
// <input type="text" placeholder="Text Input" class="{{modifier_class}}">
// <input type="email" placeholder="Email Input" class="{{modifier_class}}">
// <input type="date" placeholder="Date Input" class="{{modifier_class}}" />
// <input type="password" placeholder="Password Input" class="{{modifier_class}}">
// <input type="search" placeholder="Search Input" class="{{modifier_class}}">
// <input type="tel" placeholder="Telephone Input" class="{{modifier_class}}">
// <textarea class="{{modifier_class}}" placeholder="Textarea"></textarea>
//
// Weight: -99
//
// Styleguide Forms.Input Fields
input[type='text'],
input[type='email'],
input[type='tel'],
select,
textarea {
  background-color: primary-color(gray, tint2);
  width: 100%;
  margin: 0 0 15px;
  padding: 13px 15px;
  font-family: $header-font;
  font-size: 16px; // leave at mobile sizes to prevent iphone zoom
  color: primary-color(gray, shade1);
  border: 1px solid #ddd;
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  transition: $ts $te;

  @include from(6) {
    @include rem(font-size, 14px);
  }

  &:focus {
    outline: 0;
    border-color: primary-color(blue);

    &::placeholder {
      color: primary-color(gray, shade1);
    }
  }

  &.-invalid {
    border: 1px solid $error-color;
    color: $error-color;
  }
}

textarea {
  min-height: 135px;
}

// Select Elements
//
// :focus      - Focus state
//
// Markup:
// <select class="{{modifier_class}}">
//   <option value="option1">Option 1</option>
//   <option value="option2">Option 2</option>
//   <option value="option3">Option 3</option>
//   <option value="option4">Option 4</option>
//   <option value="option5">Option 5</option>
// </select>
//
// Weight: -98
//
// Styleguide Forms.Select Elements
select {
  display: block;
  padding: 18px 35px 18px 15px;
  appearance: none;
  background-image: url('../images/select-arrow-down.svg');
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 12px;
  text-decoration: none;

  @include from(6) {
    padding: 20px 35px 20px 15px;
  }

  &:hover {
    cursor: pointer;
  }

  &::-ms-expand {
    // for IE 11
    display: none;
  }
}

// Checkboxes
//
// Markup:
// <div class="checks">
//   <div class="check">
//     <input type="checkbox" id="c1" name="cb" checked>
//     <label for="c1">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis.</label>
//   </div>
//   <div class="check">
//     <input type="checkbox" id="c2" name="cb">
//     <label for="c2">Option Two</label>
//   </div>
//   <div class="check">
//     <input type="checkbox" id="c3" name="cb">
//     <label for="c3">Option Three</label>
//   </div>
// </div>
//
// Weight: -97
//
// Styleguide Forms.Checkboxes

// Radio Buttons
//
// Markup:
// <div class="radios">
//   <div class="radio">
//     <input type="radio" id="r1" name="r" checked>
//     <label for="r1">Option One</label>
//   </div>
//   <div class="radio">
//     <input type="radio" id="r2" name="r">
//     <label for="r2">Option Two that wraps Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</label>
//   </div>
//   <div class="radio">
//     <input type="radio" id="r3" name="r">
//     <label for="r3">Option Three</label>
//   </div>
// </div>
//
// Weight: -96
//
// Styleguide Forms.Radio Buttons
// .checks,
// .radios {
//   margin: 25px 0;
//
//   .check,
//   .radio {
//     position: relative;
//     margin-bottom: 15px;
//   }
//
//   label {
//     margin: 2px 0 0;
//     padding: 0 0 0 32px;
//     width: auto;
//     position: relative;
//
//     @include icon {
//       display: inline-block;
//       position: absolute;
//       top: -1px;
//       left: 0;
//       cursor: pointer;
//       color: transparent;
//       @include rem(font-size, 14px);
//       line-height: 17px;
//       text-align: center;
//       width: 22px;
//       height: 22px;
//       border: 1px solid #b9b9b9;
//       transition: $ts $te;
//     }
//
//     &:hover {
//       cursor: pointer;
//     }
//   }
//
//   input[type='checkbox'],
//   input[type='radio'] {
//     display: none;
//
//     &:checked + label {
//
//       &::before {
//         background: primary-color(blue, shade1);
//         color: primary-color(white);
//         border-color: primary-color(blue, shade1);
//       }
//     }
//
//     &:not(:checked) + label {
//
//       &:hover,
//       &:focus {
//
//         &::before {
//           background: rgba(primary-color(blue, shade1), .5);
//           color: rgba(primary-color(white), .75);
//           border-color: rgba(primary-color(blue, shade1), .5);
//           box-shadow: none;
//         }
//       }
//     }
//   }
//
//   input[type='checkbox'] {
//
//     & + label {
//       @include icon(check, before, false);
//     }
//   }
//
//   input[type='radio'] {
//
//     & + label {
//       @include icon(circle, before, false) {
//         border-radius: 50%;
//       };
//     }
//   }
//
//   .error-message {
//     margin-top: 8px;
//     padding-left: 35px;
//   }
// }
