// Icomoon variables
$icons: (
  lock: '\e90a',
  directions: '\e909',
  hours: '\e905',
  location: '\e906',
  pdf: '\e907',
  phone: '\e908',
  banking: '\e902',
  business: '\e903',
  wealth-management: '\e904',
  caret: '\e901',
  menu: '\e900'
);

[class^='icon-'],
[class*=' icon-'] {

  &::before {
    @include icomoon;
  }
}
