* {
  box-sizing: border-box;

  &::after,
  &::before {
    box-sizing: border-box;
  }
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

body,
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // disable webkit tap highlight
}

body {
  position: relative;
  font-family: $body-font;
  font-size: $base-font-size;
  color: $body-text-color;
  min-width: 320px;
  height: 100%;
  line-height: 1.4;

  &.-mobile-menu-open {
    overflow: hidden;
  }
}

// Horizontal Rules
//
// Wrapper:
// padding-top: 0;
// padding-bottom: 0;
//
// Markup:
// <hr>
//
// Styleguide Components.Horizontal Rules
hr {
  margin: 30px auto;
  max-width: 1010px;
  border: 0;
  border-bottom: solid 1px rgba(primary-color(black), .1);
  background-color: transparent;
  clear: both;
}

// Images
//
// Wrapper:
// max-width: 800px;
//
// Markup:
// <img src="https://placekitten.com/900/400" alt="Kittens!">
//
// Styleguide Components.Images
img {
  max-width: 100%;
  height: auto;
  display: block;
}

// Video Containers
//
// Wrapper:
// max-width: 800px;
//
// Markup:
// <div class="video-container">
//  <iframe width="560" height="315" src="https://www.youtube.com/embed/56ucT_Hw4bg" frameborder="0" allowfullscreen></iframe>
// </div>
//
// Styleguide Components.Video Containers
// .video-container {
//   position: relative;
//   padding-bottom: 56.25%;
//   height: 0;
//   overflow: hidden;
//
//   .placeholder,
//   embed,
//   iframe,
//   object {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//   }
// }

@mixin sr-text {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  position: absolute;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

// Screen reader text
.sr-text {
  @include sr-text;
}
