// Links
//
// :hover    - Hover state
// :focus    - Focus state
// :active   - Active state
// :disabled - Disabled state
//
// Markup:
// This is a <a class="{{modifier_class}}" href="#">standard link</a>
//
// Weight: -99
//
// Styleguide Components.Links
a {
  display: inline-block;
  color: primary-color(blue, shade1);
  text-decoration: none;
  transition: $ts $te;

  &:focus {
    outline: 0;
  }

  &:active,
  &:focus,
  &:hover {
    color: primary-color(navy);
  }

  &:disabled,
  &[disabled] {
    opacity: .5;
    pointer-events: none;
  }

  p & {

    .wysiwyg-content & {
      @include font-weight(bold);
      display: inline;
      border-bottom: 1px solid transparent;

      &:hover,
      &:focus {
        border-color: primary-color(navy);
      }
    }
  }

  &.-pdf {
    position: relative;
    padding: 14px 0 0 32px;
    text-decoration: none;
    @include rem(font-size, 16px);
    @include font-weight(bold);

    @include icon(pdf) {
      position: absolute;
      top: 11px;
      left: 0;
      color: primary-color(gray, tint1);
      @include font-weight(normal);
      @include rem(font-size, 24px);
    }
  }

  &.-jump-link {
    margin-top: -20px;
    display: inline;
  }
}
