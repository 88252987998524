// Content Accordion
//
// Generic sitewide accordion.  Items can be opened at will.  Other items will not auto-close.
//
// Markup:
// <div class="accordion-wrapper">
//      <ul class="accordion">
//        <li>
//          <div class="accordion-trigger">
//            <a href="#">Lorem ipsum dolor sit amet</a>
//          </div>
//          <div class="accordion-content">
//            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
//          </div>
//        </li>
//        <li>
//          <div class="accordion-trigger">
//            <a href="#">Lorem ipsum dolor sit amet</a>
//          </div>
//          <div class="accordion-content">
//            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
//          </div>
//        </li>
//      </ul>
// </div>
//
// Styleguide Components.Accordion
.accordion-wrapper {
  max-width: 1010px;
  padding: 0 20px;
  margin: 0 auto;

  @include from(11) {
    padding: 0;
  }

  .section-title {
    color: primary-color(black);
  }
}

.accordion {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    list-style: none;
    margin-bottom: 4px;
  }

  &-trigger {
    @include rem(font-size, 18px);

    > a {
      position: relative;
      padding: 14px 0 0 32px;
      text-decoration: none;
      @include rem(font-size, 16px);
      @include font-weight(bold);

      @include icon(caret) {
        position: absolute;
        top: 13px;
        left: 0;
        width: 20px;
        height: 20px;
        color: primary-color(gray, tint1);
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        transition: $ts $te;
        transform: rotate(-90deg);
        border: 2px solid primary-color(gray, tint1);
        border-radius: 50%;
      }

      &:hover,
      &:focus {

        &::before {
          color: primary-color(gray, tint1);
        }
      }
    }

    &.-active {

      > a {
        color: primary-color(blue, shade2);

        &::before {
          transform: rotate(0);
          color: primary-color(blue, tint1);
          border: 2px solid primary-color(blue, tint1);
        }
      }
    }
  }

  &-content {
    display: none;
    padding: 10px 0 0 32px;
    max-width: 767px;

    > p {
      font-family: $header-font;
      @include rem(font-size, 14px);
    }
  }
}
