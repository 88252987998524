.text-block {
  padding: 0 20px;
  margin: 45px 0;
  text-align: center;

  &-inner {
    max-width: 1010px;
    margin: 0 auto;
  }
}
