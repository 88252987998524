.location-tabs-top {
  text-align: center;
  padding: 20px;
}

.js-location-tabs {
  display: flex;
  position: relative;
  justify-content: center;
  list-style: none;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 0 4px;
  border-bottom: solid 1px rgba(primary-color(black), .1);

  li {
    display: block;
    margin: 0 5px;
    padding: 14px 5px;
    font-family: $header-font;
    color: primary-color(gray);
    letter-spacing: .03em;
    @include font-weight(black);
    @include rem(font-size, 12px);
    cursor: pointer;
    position: relative;
    top: 4px;
    border-bottom: 4px solid transparent;
    transition: $ts $te;

    @include from(4) {
      padding: 14px 9px;
      @include rem(font-size, 14px);
    }

    &:hover,
    &:focus,
    &:active {
      border-color: primary-color(blue);
      color: primary-color(navy);
    }

    &.-active {
      border-color: primary-color(blue);
      color: primary-color(navy);
    }
  }
}

.tab-content {
  position: absolute;
  top: -99999px;
  opacity: 0;
  visibility: hidden;
  margin: 0 auto;
  max-width: 1280px;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  padding: 20px 20px 45px;
  transition: .3s $te;


  @include breakpoint(1310px) {
    padding: 20px 0 80px;
  }



  &.-active {
    position: static;
    opacity: 1;
    visibility: visible;

    @include from(11) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  .location {
    @include from(11) {
      width: 48%;
      //margin: 0 25px;
    }

    @include from(13) {
      width: 46%;

    }
  }

  .all-locations-link {
    width: 100%;
    margin-top: 30px;
    font-family: $header-font;
    @include rem(font-size, 11px);
    @include font-weight(black);
    letter-spacing: .07em;
    text-transform: uppercase;


    @include icon(caret, after) {
      @include rem(font-size, 16px);
      margin-left: 4px;
      position: relative;
      top: 2px;
      left: 0;
      transform: rotate(-90deg);
      transition: $ts $te;
    }

    &:focus,
    &:hover {
      opacity: .9;

      &::after {
        left: 3px;
      }
    }
  }
}
