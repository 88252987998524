.images-grid {
  padding: 45px 20px;

  background: primary-color(navy, shade1);

  @include from(9) {
    padding: 60px 20px;
  }

  &-inner {
    max-width: 1280px;
    margin: 0 auto;
  }

  &-intro {
    text-align: center;
    max-width: 1080px;
    margin: 0 auto;
    color: primary-color(white);

    h3 {
      color: primary-color(white);
    }
  }

  // &-items {
  //
  //   @include from(6) {
  //     display: flex;
  //     flex-wrap: wrap;
  //     justify-content: center;
  //   }
  //
  //   @include from(9) {
  //     margin: 0 -15px;
  //   }
  //
  //   @include from(12) {
  //     margin: 0 -20px;
  //   }
  // }

  // a {
  //
  //   &:hover,
  //   &:focus {
  //
  //     .images-grid-item-image-wrapper {
  //
  //       img {
  //         transform: scale(1.1);
  //       }
  //     }
  //
  //     .images-grid-item-title {
  //       color: primary-color(blue);
  //     }
  //   }
  // }

  // &-item {
  //   display: block;
  //   text-align: center;
  //   margin: 30px 0;
  //
  //   @include from(6) {
  //     width: calc(50% - 10px);
  //     margin: 20px 0;
  //
  //     &:nth-child(odd) {
  //       margin-right: 10px;
  //     }
  //
  //     &:nth-child(even) {
  //       margin-left: 10px;
  //     }
  //   }
  //
  //   @include from(9) {
  //     width: calc(100% / 3 - 30px);
  //
  //     &:nth-child(n) {
  //       margin: 20px 15px;
  //     }
  //   }
  //
  //   @include from(12) {
  //     width: calc(25% - 40px);
  //
  //     &:nth-child(n) {
  //       margin: 20px;
  //     }
  //   }
  //
  //   &-image-wrapper {
  //     margin-bottom: 20px;
  //     overflow: hidden;
  //     position: relative;
  //
  //     &::before {
  //       content: '';
  //       display: block;
  //       padding-bottom: 66.6667%;
  //     }
  //
  //     img {
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       width: 100%;
  //       transform: scale(1);
  //       transition: .4s $te;
  //     }
  //   }
  //
  //   &-title {
  //     color: primary-color(white);
  //     margin-bottom: 15px;
  //     transition: $ts $te;
  //
  //     @include from(4) {
  //       max-width: 75%;
  //       margin-left: auto;
  //       margin-right: auto;
  //     }
  //   }
  //
  //   &-text {
  //     color: primary-color(white);
  //     font-family: $header-font;
  //     @include rem(font-size, 14px);
  //     letter-spacing: .05em;
  //     line-height: 1.6;
  //     transition: $ts $te;
  //
  //     @include from(4) {
  //       max-width: 75%;
  //       margin: 0 auto;
  //     }
  //   }
  // }
}
