.hero {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: primary-color(blue, shade2);
  color: primary-color(white);
  font-family: $header-font;
  position: relative;
  overflow: hidden;
  padding: 170px 20px 70px;
  text-align: center;

  @include from(7) {
    min-height: 475px;
    text-align: left;
  }

  &-ellipse {
    position: absolute;
    top: -100%;
    left: -18%;
    width: 80%;
    max-width: none;

    @include from(7) {
      top: -80%;
      left: -18%;
    }

    @include from(12) {
      top: -110%;

      width: 968px;
    }
  }

  &-inner {
    flex: 1 1 auto;
    max-width: 1280px;
    margin: 0 auto;
    position: relative;

    @include from(7) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-content {
    margin-bottom: 35px;

    @include from(7) {
      width: 66%;
      max-width: 555px;
      margin-bottom: 0;
    }

    @include from(9) {
      width: 60%;
    }

    @include from(10) {
      width: 70%;
    }
  }

  &-title {
    @include rem(font-size, 30px);
    color: primary-color(white);

    @include from(7) {
      @include rem(font-size, 34px);
    }

    @include from(10) {
      @include rem(font-size, 40px);
    }

    strong {
      @include rem(font-size, 40px);
      @include font-weight(black);

      @include from(10) {
        @include rem(font-size, 50px);
      }
    }
  }

  &-login {
    padding: 20px;
    background: primary-color(navy, shade1);
    text-align: left;
    flex: 0 1 auto;

    @include from(7) {
      width: 33%;
      margin-left: 20px;
    }

    @include from(9) {
      width: 40%;
      padding: 30px 40px;
    }

    @include from(10) {
      width: 30%;
    }

    @include from(11) {
      margin: 0 50px;
    }

    @include from(13) {
      margin: 0 100px;
    }

    .login-title {
      position: relative;
      color: primary-color(white);
      margin-bottom: 10px;
      padding-left: 28px;
      @include rem(font-size, 18px);

      @include icon(lock) {
        position: absolute;
        top: 3px;
        left: 0;
        opacity: .5;
      }
    }

    .button {
      width: 100%;
      text-align: left;
      line-height: 1.5;

      &:last-child {
        margin-top: 10px;
      }
    }
  }
}
