.staggered-three-up {
  padding: 0 20px;
  margin: 45px 0;

  @include from(9) {
    margin: 60px 0;
  }

  &-inner {
    max-width: 1280px;
    margin: 0 auto;

    @include from(9) {
      display: flex;
    }
  }

  &-block {
    position: relative;
    text-align: center;
    padding: 30px 20px;
    box-shadow: 0 12px 18px 0 rgba(0, 0, 0, .2);
    margin-bottom: 30px;
    overflow: hidden;

    @include from(7) {
      padding: 50px;
    }

    @include from(9) {
      flex: 1 1 0;
      margin: 0;
    }

    &::before {
      content: '';
      position: absolute;
      width: 150%;
      height: 300%;
      border-radius: 100%;
      background: lime;
      backface-visibility: hidden;
    }

    &-inner {
      position: relative;
    }

    &-icon,
    &-title,
    &-text {
      color: primary-color(white);
    }

    &-icon {
      @include rem(font-size, 120px);
    }

    &-title {
      margin-bottom: 15px;
    }

    &-text {
      font-family: $header-font;
      @include rem(font-size, 14px);
      letter-spacing: .01em;
      line-height: 1.6;
      margin-bottom: 15px;
    }

    &-link {
      width: 100%;
      margin-top: 30px;
      font-family: $header-font;
      @include rem(font-size, 11px);
      @include font-weight(black);
      letter-spacing: .07em;
      text-transform: uppercase;
      color: primary-color(white);


      @include icon(caret, after) {
        @include rem(font-size, 16px);
        margin-left: 4px;
        position: relative;
        top: 2px;
        left: 0;
        transform: rotate(-90deg);
        transition: $ts $te;
      }

      &:focus,
      &:hover {
        color: primary-color(white);
        opacity: .9;

        &::after {
          left: 3px;
        }
      }

    }

    &:first-child {
      background-color: primary-color(blue, shade2);

      @include from(9) {
        margin-top: 60px;
      }

      &::before {
        top: -180%;
        left: 3%;
        opacity: .5;
        background: lighten(primary-color(blue, shade2), 3%);
      }
    }

    &:nth-child(2) {
      background-color: primary-color(green);
      z-index: 10;

      &::before {
        top: -180%;
        left: -82%;
        background: darken(primary-color(green), 4%);
      }

      @include from(9) {
        margin-bottom: 60px;
      }
    }

    &:last-child {
      background-color: primary-color(gray);

      @include from(9) {
        margin-top: 40px;
        margin-bottom: 10px;
      }

      &::before {
        top: -170%;
        left: -50%;
        background: lighten(primary-color(gray, tint1), 3%);
      }
    }
  }
}
