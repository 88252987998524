.location {
  width: 100%;
  padding: 30px 0;
  border-bottom: 2px solid rgba(primary-color(black), .08);

  @include from(6) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &-title {
    width: 100%;
    margin-bottom: 10px;
    @include rem(font-size, 20px);
    font-family: $body-font;
    @include font-weight(normal);
    color: primary-color(black);
  }


  &-details,
  &-contact {
    font-family: $header-font;
    @include rem(font-size, 14px);

    a {
      @include font-weight(bold);
      border-bottom: 1px solid transparent;
      line-height: 1.3;

      &:hover,
      &:focus {
        border-color: primary-color(navy);
      }
    }
  }

  &-details {
    letter-spacing: -.01em;
    margin-bottom: 25px;

    @include from(6) {
      width: 50%;
    }

    @include from(9) {
      flex: 1 1 0;
      max-width: 430px;
      padding-left: 0;
      padding-right: 20px;
      margin-bottom: 0;
    }



    &-address {
      position: relative;
      padding-left: 25px;
      margin-bottom: 10px;

      @include icon(location) {
        position: absolute;
        top: 3px;
        left: 0;
        width: 16px;
        text-align: center;
        color: #999;
      }
    }

    &-directions {
      display: flex;
      align-items: center;

      &-item {
        position: relative;
        padding-left: 25px;

        @include icon {
          position: absolute;
          top: 2px;
          left: 0;
          width: 16px;
          text-align: center;
          color: #999;
        }

        &:first-child {
          @include icon(directions, before, false);
        }
      }

      &-separator {
        flex: none;
        display: inline-block;
        color: #999;
        @include rem(font-size, 16px);
        margin: 0 10px 0 8px;
      }
    }
  }

  &-contact {
    letter-spacing: .02em;

    @include from(6) {
      width: 100%;
    }

    @include from(9) {
      flex: 1 1 0;
      max-width: 330px;
      padding-left: 0;
    }

    &-hours,
    &-phone {
      position: relative;
      padding-left: 25px;

      @include icon {
        position: absolute;
        top: 2px;
        left: 0;
        width: 25px;
        text-align: center;
        color: #999;
      }
    }

    &-phone {
      @include icon(phone, before, false);
    }

    &-hours {
      margin-bottom: 10px;
      @include icon(hours, before, false);
    }
  }
}
