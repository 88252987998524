.insights-grid {
  padding: 60px 20px;
  margin: 60px 0 20px;
  background-image: url('../images/bg-swoops-right.jpg');
  background-size: cover;
  background-position: center;

  @include from(9) {
    margin: 80px 0 20px;
  }

  &-inner {
    max-width: 1280px;
    margin: 0 auto;
  }

  &-top {
    text-align: center;
    margin-bottom: 30px;

    h3 {
      color: primary-color(white);
    }
  }

  &-items {
    margin-top: 25px;

    .insight-teaser {
      margin-bottom: 30px;
    }

    @include from(6) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;

      & > * {
        width: calc(50% - 30px);
        margin: 0 15px 30px;
      }
    }

    @include from(12) {

      & > * {
        width: auto;
        flex: 1 1 0;
      }
    }
  }

  &-all-link {
    text-align: right;
    margin-top: 10px;

    @include from(6) {
      margin-top: -10px;
    }

    a {
      font-family: $header-font;
      @include rem(font-size, 11px);
      @include font-weight(black);
      letter-spacing: .07em;
      text-transform: uppercase;
      color: primary-color(white);

      @include icon(caret, after) {
        @include rem(font-size, 16px);
        margin-left: 4px;
        position: relative;
        top: 2px;
        left: 0;
        transform: rotate(-90deg);
        transition: $ts $te;
      }

      &:focus,
      &:hover {
        opacity: .9;

        &::after {
          left: 3px;
        }
      }
    }
  }
}
