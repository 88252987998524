// Buttons
//
// .-arrow   - Buttons with Arrow
// :hover    - Hover state
// :focus    - Focus state
// :active   - Active state
// :disabled - Disabled state
//
// Markup:
// <button class="{{modifier_class}}">Standard Button</button>
// <a class="btn {{modifier_class}}" href="#">Link Button</a>
//
// Weight: -98
//
// Styleguide Components.Buttons
button,
.btn,
.button,
.hs-button,
input[type='submit'] {
  position: relative;
  display: inline-block;
  border: 0;
  background: primary-color(green);
  border-radius: $border-radius;
  color: primary-color(white);
  cursor: pointer;
  font-family: $header-font;
  @include rem(font-size, 14px);
  @include font-weight(black);
  letter-spacing: .02em;
  line-height: 1;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  transition: background-color $ts $te;

  &:active,
  &:focus,
  &:hover {
    background: darken(primary-color(green), 10%);
    outline: 0;
    color: primary-color(white);
  }

  &:disabled {
    opacity: .5;
    pointer-events: none;
  }

  &.-gray {
    background-color: primary-color(gray, shade1);

    &:active,
    &:focus,
    &:hover {
      background: darken(primary-color(gray), 10%);
    }
  }

  &.-arrow {
    padding: 20px 38px 20px 20px;

    @include icon(caret, after) {
      position: absolute;
      top: 50%;
      transform: rotate(-90deg);
      right: 10px;
      @include rem(font-size, 18px);
      margin-top: -9px;
    };
  }
}
