//trigger
.mobile-menu-trigger {
  display: block;
  position: absolute;
  width: 42px;
  height: 42px;
  color: primary-color(white);
  text-align: center;
  top: 20px;
  right: -10px;
  transition: $ts $te;
  z-index: 500;

  @include from(7) {
    display: none;
  }

  @include icon(menu) {
    @include rem(font-size, 30px);
  }

  &:hover,
  &:focus {
    color: primary-color(white);
  }
}

//menu
.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  margin: 0 -250px 0 0;
  transition: $ts $te;
  z-index: 11;

  @include from(7) {
    display: none;
  }

  &.-open {
    margin: 0;

    // .mobile-menu-blocker {
    //   display: block;
    //   position: fixed;
    //   background: rgba(primary-color(black), .5);
    //   width: 100%;
    //   height: 100%;
    //   z-index: 10;
    //   overflow: hidden;
    // }
  }

  ul {
    width: 250px;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    background: darken(primary-color(navy, shade1), 10%);
    overflow: hidden;
  }

  li {
    margin: 0;
  }

  a {
    color: primary-color(white);
    font-family: $body-font;
    @include rem(font-size, 14px);
    text-decoration: none;
    display: block;
    padding: 27.5px 20px;
    border-bottom: 1px solid rgba(primary-color(white), .2);

    &:hover {
      background: rgba(primary-color(black), .9);
    }
  }
}
