.site-header {
  position: relative;
  z-index: 10;
  background: rgba(primary-color(navy, shade1), .57);
  padding: 0 20px;
  margin-bottom: -105px;

  @include from(10) {
    margin-bottom: -114px;
  }

  .site-header-inner {
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-left {
    flex: none;
    margin-right: 30px;
    padding: 20px 0;

    @include from(7) {
      padding: 0;
    }

    .site-header-logo {
      width: 150px;
      display: block;

      @include from(9) {
        width: 160px;
      }

      img {
        width: 100%;
      }
    }
  }

  &-right {
    flex: none;

    @include from(7) {
      padding-top: 30px;
    }
  }
}

.primary-nav {
  display: none;

  @include from(7) {
    display: block;
  }

  ul {
    @include kill-bullets;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;

    li {
      flex: none;

      & + li {
        margin-left: 24px;
      }

      a {
        color: primary-color(white);
        @include rem(font-size, 14px);
        @include font-weight(bold);
        letter-spacing: .03em;
        padding-bottom: 26px;
        border-bottom: 4px solid transparent;

        &:focus,
        &:hover {
          border-color: secondary-color(yellow);
        }
      }
    }
  }
}
