

// Grayscale Colors
//
// Colors:
// primary-color(black)        : #000 - Black
// primary-color(gray, shade1) : #333 - Dark Gray
// primary-color(gray)         : #666 - Gray
// primary-color(white)        : #fff - White
//
// Styleguide Colors.Grayscale Colors


// Primary Colors
//
// Colors:
// primary-color(navy)         : #00497e - Navy
// primary-color(navy, shade1) : #002d4d - Navy (Shade 1)
// primary-color(blue)         : #0091d0 - Blue
// primary-color(blue, shade1) : #007db3 - Blue (Shade 1)
// primary-color(blue, tint1)  : #60b3e3 - Blue (Tint 1)
//
// Styleguide Colors.Primary Colors
$primarycolors: (
  white: (
    base: #fff
  ),
  black: (
    base: #000
  ),
  gray: (
    base: #6c6d6d,
    shade1: #606161,
    tint1: #6c6d6d,
    tint2: #f7f7f7
  ),
  navy: (
    base: #00497e,
    shade1: #004577
  ),
  blue: (
    base: #0091d0,
    shade1: #007db3,
    shade2: #005695,
    tint1: #60b3e3
  ),
  green: (
    base: #659f8a
  )
);

// Secondary Colors
//
// Colors:
// secondary-color(blue)    : #5d7e96 - Blue
// secondary-color(yellow)  : #f6cc66 - Yellow
// secondary-color(green)   : #56b22f - Green
// secondary-color(olive)   : #5a7348 - Olive
//
// Styleguide Colors.Secondary Colors
$secondarycolors: (
  blue: (
    base: #5d7e96
  ),
  yellow: (
    base: #f6cc66
  ),
  green: (
    base: #56b22f,
    shade1: #3d9317
  ),
  olive: (
    base: #5a7348
  )
);

// Social Media Colors
//
// Colors:
// sm-color(twitter)     : #00b6f1 - Twitter
// sm-color(facebook)    : #3b5998 - Facebook
// sm-color(googleplus)  : #df4a32 - Google Plus
// sm-color(pinterest)   : #cb2027 - Pinterest
// sm-color(linkedin)    : #007bb6 - LinkedIn
// sm-color(youtube)     : #b31217 - YouTube
// sm-color(instagram)   : #c32aa3 - Instagram
// sm-color(snapchat)    : #fffc00 - Snapchat
//
// Styleguide Colors.Social Media Colors
$socialmediacolors: (
  twitter:    #00b6f1,
  facebook:   #3b5998,
  googleplus: #df4a32,
  pinterest:  #cb2027,
  linkedin:   #007bb6,
  youtube:    #b31217,
  instagram:  #c32aa3,
  snapchat:   #fffc00,
);
