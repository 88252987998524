.page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: $ts $te;

  .-mobile-menu-open & {
    transform: translate3d(-250px, 0, 0);

    @include from(7) {
      transform: translate3d(0, 0, 0);
    }
  }

  & > main {
    flex: 1 0 auto;
  }
}
