@import 'breakpoint-slicer/breakpoint-slicer';

// Font Weights
//
// Compiles a string font-weight to it's associated integer font weight
//
// $weight = null - Desired font weight as a string
//
// Definition:
// @mixin font-weight($weight) {
//   $weights: (
//     thin: 100,
//     extra-light: 200,
//     ultra-light: 200,
//     light: 300,
//     normal: 400,
//     book: 400,
//     regular: 400,
//     roman: 400,
//     medium: 500,
//     semi-bold: 600,
//     demi-bold: 600,
//     bold: 700,
//     extra-bold: 800,
//     ultra-bold: 900,
//     heavy: 900,
//     black: 900,
//     ultra: 900,
//     ultra-black: 900,
//     extra-ultra: 900
//   );
//   $output: $weight;
//   @if map-has-key($weights, $weight) {
//     $output: map-get($weights, $weight);
//   }
//   font-weight: $output;
// }
//
// Usage:
// .sample {
//   @include font-weight(medium);
// }
//
// Compiled:
// .sample {
//   font-weight: 500;
// }
//
// Styleguide Sass Mixins.Font Weights
@mixin font-weight($weight) {
  $weights: (
    thin: 100,
    extra-light: 200,
    ultra-light: 200,
    light: 300,
    normal: 400,
    book: 400,
    regular: 400,
    roman: 400,
    medium: 500,
    semi-bold: 600,
    demi-bold: 600,
    bold: 700,
    extra-bold: 800,
    ultra-bold: 900,
    heavy: 900,
    black: 900,
    ultra: 900,
    ultra-black: 900,
    extra-ultra: 900
  );

  $output: $weight;
  @if map-has-key($weights, $weight) {
    $output: map-get($weights, $weight);
  }

  font-weight: $output;
}

// Icomoon required styles
@mixin icomoon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// Icomoon Icons
//
// Mixin to include Icomoon icons
//
// $icon     = null   - Name of the icon from the icons Sass map
// $position = before - Position of the icon, either before or after
// $styles   = true   - Boolean to output required Icomoon styling
//
// Definition:
// @mixin icon($icon: null, $position: before, $styles: true) {
//   &::#{$position} {
//     @if $icon {
//       content: "#{map-get($icons, $icon)}";
//     }
//     @if $styles {
//       font-family: 'icomoon' !important;
//       speak: none;
//       font-style: normal;
//       font-weight: normal;
//       font-variant: normal;
//       text-transform: none;
//       -webkit-font-smoothing: antialiased;
//       -moz-osx-font-smoothing: grayscale;
//     }
//     // Include any extra rules supplied for the pseudo-element
//     @content;
//   }
// }
//
// Usage:
// .facebook-link {
//   @include icon(facebook) {
//     color: sm-color(facebook);
//     &:hover,
//     &:focus {
//       color: #333;
//     }
//   };
// }
// .social-links {
//   a {
//     @include icon;
//     &.facebook {
//       @include icon(facebook, before, false);
//     }
//     &.twitter {
//       @include icon(twitter, before, false);
//     }
//   }
// }
//
// Compiled:
// .facebook-link::before {
//   content: "";
//   font-family: 'icomoon' !important;
//   speak: none;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   color: #3b5998;
// }
// .facebook-link::before:hover, .facebook-link::before:focus {
//   color: #333;
// }
// .social-links a::before {
//   font-family: 'icomoon' !important;
//   speak: none;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
// .social-links a.facebook::before {
//   content: "";
// }
// .social-links a.twitter::before {
//   content: "";
// }
//
// Styleguide Sass Mixins.Icomoon Icons
@mixin icon($icon: null, $position: before, $styles: true) {
  // Either a ::before or ::after pseudo-element, defaulting to ::before
  &::#{$position} {
    @if $icon {
      // A particular icon has been specified
      content: "#{map-get($icons, $icon)}";
    }
    @if $styles {
      @include icomoon;
    }
    // Include any extra rules supplied for the pseudo-element
    @content;
  }
}


//
// Rem Mixin Stuff
//
$rem-baseline: 16px !default;
$rem-fallback: true !default;
$rem-px-only: false !default;

// list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
@function rem-separator($list) {
  @if function-exists("list-separator") == true {
    @return list-separator($list);
  }

  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);

  @each $value in $values {
    @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
      $result: append($result, $value / ($rem-baseline / 1rem), $separator);
    } @else if type-of($value) == "list" {
      $result: append($result, rem-convert($to, $value...), $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return $result;
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

// Rem Units
//
// Converts a pixel value to rem and includes a pixel value as a fallback
//
// $properties = null - Properties to convert to rem
// $values   = null - Pixel values to convert
//
// Definition:
// @mixin rem($properties, $values...) {
//   @if type-of($properties) == "map" {
//     @each $property, $values in $properties {
//       @include rem($property, $values...);
//     }
//   } @else {
//     @each $property in $properties {
//       @if $rem-fallback or $rem-px-only {
//         #{$property}: rem-convert(px, $values...);
//       }
//       @if not $rem-px-only {
//         #{$property}: rem-convert(rem, $values...);
//       }
//     }
//   }
// }
//
// Usage:
// .sample {
//   @include rem(font-size, 16px);
// }
//
// Compiled:
// .sample {
//   font-size: 16px;
//   font-size: 1rem;
// }
//
// Styleguide Sass Mixins.Rem Units
@mixin rem($properties, $values...) {
  @if type-of($properties) == "map" {
    @each $property, $values in $properties {
      @include rem($property, $values...);
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}


// Clearfix
//
// Clears floated children
//
// Definition:
// @mixin clearfix-micro {
//   &:after {
//     content: '';
//     display: block;
//     clear: both;
//   }
// }
//
// Styleguide Sass Mixins.Clearfix
@mixin clearfix-micro {

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}


// Kill bullets
//
// Removes bullets from lists
//
// $margin  = 0 - Margin for list
// $padding = 0 - Padding for list
//
// Definition:
// @mixin kill-bullets($margin: 0, $padding: 0) {
//   margin: $margin;
//   padding: $padding;
//   list-style: none;
// }
//
// Usage:
// ul {
//   @include kill-bullets;
// }
// ol {
//   @include kill-bullets(0 0 20px, 0 0 0 15px);
// }
//
// Compiled:
// ul {
//   margin: 0;
//   padding: 0;
//   list-style: none;
// }
// ol {
//   margin: 0 0 20px;
//   padding: 0 0 0 15px;
//   list-style: none;
// }
//
// Styleguide Sass Mixins.Kill Bullets
@mixin kill-bullets($margin: 0, $padding: 0) {
  margin: $margin;
  padding: $padding;
  list-style: none;
}


// Absolute
//
// Absolulety positions an element
//
// $top    = null - Top value
// $right  = null - Right value
// $bottom = null - Bottom value
// $left   = null - Left value
//
// Definition:
// @mixin absolute($top:null, $right:null, $bottom:null, $left:null) {
//   position: absolute;
//   @if $top    { top: $top; }
//   @if $right  { right: $right; }
//   @if $bottom { bottom: $bottom; }
//   @if $left   { left: $left; }
// }
//
// Usage:
// .sample {
//   @include absolute(10px, 10px, 5px, 15px);
// }
//
// Compiled:
// .sample {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   bottom: 5px;
//   left: 15px;
// }
//
// Styleguide Sass Mixins.Absolute
@mixin absolute($top:null, $right:null, $bottom:null, $left:null) {
  position: absolute;
  @if $top    { top: $top; }
  @if $right  { right: $right; }
  @if $bottom { bottom: $bottom; }
  @if $left   { left: $left; }
}


// Move
//
// Moves an element a distance in the specified direction
//
// $direction = null - Direction to move (up, right, down, left)
// $distance  = null - Distance to move (px, rem, em, etc.)
//
// Definition:
// @mixin move($direction, $distance) {
//   position: relative;
//   @if ($direction == 'up')    { top: -$distance; }
//   @if ($direction == 'right') { right: $distance; }
//   @if ($direction == 'down')  { top: $distance; }
//   @if ($direction == 'left')  { left: $distance; }
// }
//
// Usage:
// .sample {
//   @include move(left, 5px);
// }
//
// Compiled:
// .sample {
//   position: relative;
//   left: 5px;
// }
//
// Styleguide Sass Mixins.Move
@mixin move($direction, $distance) {
  position: relative;
  @if ($direction == 'up')    { top: -$distance; }
  @if ($direction == 'right') { right: $distance; }
  @if ($direction == 'down')  { top: $distance; }
  @if ($direction == 'left')  { left: $distance; }
}
