.resources {
  max-width: 1010px;
  padding: 0 20px;
  margin: 0 auto;

  @include from(11) {
    padding: 0;
  }

  .section-title {
    color: primary-color(black);
  }
}
