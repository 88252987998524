// Headers
//
// Wrapper:
// max-width: 960px;
//
// Markup:
// <h1>h1 (50px) Lato Black - Find a Location</h1>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
// <h2>h2 (50px) Lato Regular - News & Insights</h2>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
// <h3>h3 (50px) Lato Black - Find your banker or advisor</h3>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
// <h4>h4 (22px) Lato Black - Market Commentary: Good to Great</h4>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
//
// Weight: -99
//
// Styleguide Typography.Headers
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  margin: 0 0 20px;
  font-family: $header-font;
  @include font-weight(black);
  color: $header-color;
  transition: font-size $ts $te;

  a {
    text-decoration: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.h1,
h1 {
  @include rem(font-size, 32px);
  letter-spacing: .02em;
  line-height: 1.18;

  @include from(5) {
    @include rem(font-size, 42px);
  }

  @include from(10) {
    @include rem(font-size, 50px);
  }
}

.h2,
h2 {
  @include rem(font-size, 38px);
  @include font-weight(regular);
  color: primary-color(blue);
}

.h3,
h3 {
  @include rem(font-size, 26px);
  letter-spacing: .02em;

  @include from(5) {
    @include rem(font-size, 32px);
  }
}

.h4,
h4 {
  @include rem(font-size, 18px);
  letter-spacing: .02em;

  @include from(5) {
    @include rem(font-size, 22px);
  }
}

// Paragraphs
//
// Wrapper:
// max-width: 960px;
//
// Markup:
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
// <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. </p>
// <p>Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum gravida et. Integer lectus nisi, facilisis sit amet eleifend nec, pharetra ut augue. Integer quam nunc, consequat nec egestas ac, volutpat ac nisi. </p>
//
// Weight: -98
//
// Styleguide Typography.Paragraphs
p {
  font-family: $body-font;
  @include rem(font-size, 15px);
  line-height: 1.9;
  letter-spacing: .01em;
  margin: 0 0 25px;

  @include from(5) {
    @include rem(font-size, 16px);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.-footnote {
    @include rem(font-size, 11px);
    font-family: $header-font;

    &.-large {
      @include rem(font-size, 13px);
    }
  }
}


// Unordered Lists
//
// Wrapper:
// max-width: 960px;
//
// Markup:
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
// <ul>
//   <li>Lorem ipsum dolor</li>
//   <li>Sit amet, consectetur
//     <ul>
//       <li>Lorem ipsum dolor
//         <ul>
//           <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit</li>
//         </ul>
//       </li>
//       <li>Sit amet, consectetur</li>
//       <li>Adipisicing elit, sed</li>
//       <li>Do eiusmod tempor</li>
//       <li>Incididunt ut labore</li>
//       <li>Et dolore magna</li>
//       <li>Aliqua. Ut enim</li>
//     </ul>
//   </li>
//   <li>Adipisicing elit, sed</li>
//   <li>Do eiusmod tempor</li>
//   <li>Incididunt ut labore</li>
//   <li>Et dolore magna</li>
//   <li>Aliqua. Ut enim</li>
// </ul>
//
// Weight: -97
//
// Styleguide Typography.Unordered Lists

// Ordered Lists
//
// Wrapper:
// max-width: 960px;
//
// Markup:
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
// <ol>
//   <li>Lorem ipsum dolor</li>
//   <li>Sit amet, consectetur
//     <ul>
//       <li>Lorem ipsum dolor</li>
//       <li>Sit amet, consectetur</li>
//       <li>Adipisicing elit, sed</li>
//       <li>Do eiusmod tempor</li>
//       <li>Incididunt ut labore</li>
//       <li>Et dolore magna</li>
//       <li>Aliqua. Ut enim</li>
//     </ul>
//   </li>
//   <li>Adipisicing elit, sed</li>
//   <li>Do eiusmod tempor</li>
//   <li>Incididunt ut labore</li>
//   <li>Et dolore magna</li>
//   <li>Aliqua. Ut enim</li>
// </ol>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
//
// Weight: -96
//
// Styleguide Typography.Ordered Lists
// ol,
// ul {
//   margin: 0 0 25px;
//   font-family: $body-font;
//
//   &:last-child {
//     margin-bottom: 0;
//   }
//
//   li {
//     @include rem(font-size, 16px);
//   }
// }
//
// ul {
//
//   .wysiwyg-content & {
//     padding: 0;
//     list-style: none;
//
//     li {
//       position: relative;
//       padding-left: 15px;
//       @include rem(font-size, 15px);
//       line-height: 1.9;
//       letter-spacing: .01em;
//
//       @include from(5) {
//         @include rem(font-size, 16px);
//       }
//
//       & + li {
//         margin-top: 3px;
//       }
//
//       &::before {
//         content: '\2022';
//         color: primary-color(blue);
//         position: absolute;
//         top: 0;
//         left: 0;
//         font-family: $header-font;
//       }
//     }
//   }
// }

// Blockquotes
//
// Wrapper:
// max-width: 960px;
//
// Markup:
// <blockquote>Blockquote (24px) Lato Regular - Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed nonummynibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat lorem ipsum dolor. </blockquote>
//
// Weight: -95
//
// Styleguide Typography.Blockquotes
// blockquote {
//   padding: 5px 0 5px 30px;
//   border-left: 4px solid primary-color(blue, tint1);
//   margin: 30px 0;
//
//   @include from(4) {
//     margin: 30px 20px;
//   }
//
//   @include from(7) {
//     margin: 30px;
//   }
//
//   @include from(9) {
//     margin: 30px 40px;
//   }
//
//   &:last-child {
//     margin-bottom: 0;
//   }
//
//   p {
//     color: primary-color(navy);
//     font-family: $header-font;
//     @include rem(font-size, 18px);
//     line-height: 1.6;
//
//     @include from(6) {
//       @include rem(font-size, 23px);
//     }
//   }
// }

sup {
  letter-spacing: 0;
  @include font-weight(400);
}
