@charset "UTF-8";
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

* {
  box-sizing: border-box;
}

*::after, *::before {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

body,
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  position: relative;
  font-family: "Merriweather", serif;
  font-size: 16px;
  color: #333;
  min-width: 320px;
  height: 100%;
  line-height: 1.4;
}

body.-mobile-menu-open {
  overflow: hidden;
}

hr {
  margin: 30px auto;
  max-width: 1010px;
  border: 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  clear: both;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.sr-text {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  position: absolute;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

[class^='icon-']::before,
[class*=' icon-']::before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  margin: 0 0 20px;
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 900;
  color: #00497e;
  transition: font-size 0.15s ease-in-out;
}

h1 a,
h2 a,
h3 a,
h4 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a {
  text-decoration: none;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child {
  margin-bottom: 0;
}

.h1,
h1 {
  font-size: 32px;
  font-size: 2rem;
  letter-spacing: .02em;
  line-height: 1.18;
}

@media (min-width: 500px) {
  .h1,
  h1 {
    font-size: 42px;
    font-size: 2.625rem;
  }
}

@media (min-width: 1000px) {
  .h1,
  h1 {
    font-size: 50px;
    font-size: 3.125rem;
  }
}

.h2,
h2 {
  font-size: 38px;
  font-size: 2.375rem;
  font-weight: 400;
  color: #0091d0;
}

.h3,
h3 {
  font-size: 26px;
  font-size: 1.625rem;
  letter-spacing: .02em;
}

@media (min-width: 500px) {
  .h3,
  h3 {
    font-size: 32px;
    font-size: 2rem;
  }
}

.h4,
h4 {
  font-size: 18px;
  font-size: 1.125rem;
  letter-spacing: .02em;
}

@media (min-width: 500px) {
  .h4,
  h4 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

p {
  font-family: "Merriweather", serif;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.9;
  letter-spacing: .01em;
  margin: 0 0 25px;
}

@media (min-width: 500px) {
  p {
    font-size: 16px;
    font-size: 1rem;
  }
}

p:last-child {
  margin-bottom: 0;
}

p.-footnote {
  font-size: 11px;
  font-size: 0.6875rem;
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
}

p.-footnote.-large {
  font-size: 13px;
  font-size: 0.8125rem;
}

sup {
  letter-spacing: 0;
  font-weight: 400;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

label {
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  display: block;
  padding: 10px 0 5px;
}

.error-message {
  color: #fff;
  background: red;
  padding: 2px 4px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: -15px;
  margin-bottom: 15px;
}

input[type='text'],
input[type='email'],
input[type='tel'],
select,
textarea {
  background-color: #f7f7f7;
  width: 100%;
  margin: 0 0 15px;
  padding: 13px 15px;
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  color: #606161;
  border: 1px solid #ddd;
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  transition: 0.15s ease-in-out;
}

@media (min-width: 600px) {
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  select,
  textarea {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

input[type='text']:focus,
input[type='email']:focus,
input[type='tel']:focus,
select:focus,
textarea:focus {
  outline: 0;
  border-color: #0091d0;
}

input[type='text']:focus::placeholder,
input[type='email']:focus::placeholder,
input[type='tel']:focus::placeholder,
select:focus::placeholder,
textarea:focus::placeholder {
  color: #606161;
}

input[type='text'].-invalid,
input[type='email'].-invalid,
input[type='tel'].-invalid,
select.-invalid,
textarea.-invalid {
  border: 1px solid red;
  color: red;
}

textarea {
  min-height: 135px;
}

select {
  display: block;
  padding: 18px 35px 18px 15px;
  appearance: none;
  background-image: url("../images/select-arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 12px;
  text-decoration: none;
}

@media (min-width: 600px) {
  select {
    padding: 20px 35px 20px 15px;
  }
}

select:hover {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.15s ease-in-out;
}

.-mobile-menu-open .page-container {
  transform: translate3d(-250px, 0, 0);
}

@media (min-width: 768px) {
  .-mobile-menu-open .page-container {
    transform: translate3d(0, 0, 0);
  }
}

.page-container > main {
  flex: 1 0 auto;
}

a {
  display: inline-block;
  color: #007db3;
  text-decoration: none;
  transition: 0.15s ease-in-out;
}

a:focus {
  outline: 0;
}

a:active, a:focus, a:hover {
  color: #00497e;
}

a:disabled, a[disabled] {
  opacity: .5;
  pointer-events: none;
}

.wysiwyg-content p a {
  font-weight: 700;
  display: inline;
  border-bottom: 1px solid transparent;
}

.wysiwyg-content p a:hover, .wysiwyg-content p a:focus {
  border-color: #00497e;
}

a.-pdf {
  position: relative;
  padding: 14px 0 0 32px;
  text-decoration: none;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
}

a.-pdf::before {
  content: "";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 11px;
  left: 0;
  color: #6c6d6d;
  font-weight: 400;
  font-size: 24px;
  font-size: 1.5rem;
}

a.-jump-link {
  margin-top: -20px;
  display: inline;
}

button,
.btn,
.button,
.hs-button,
input[type='submit'] {
  position: relative;
  display: inline-block;
  border: 0;
  background: #659f8a;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 900;
  letter-spacing: .02em;
  line-height: 1;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.15s ease-in-out;
}

button:active, button:focus, button:hover,
.btn:active,
.btn:focus,
.btn:hover,
.button:active,
.button:focus,
.button:hover,
.hs-button:active,
.hs-button:focus,
.hs-button:hover,
input[type='submit']:active,
input[type='submit']:focus,
input[type='submit']:hover {
  background: #50816f;
  outline: 0;
  color: #fff;
}

button:disabled,
.btn:disabled,
.button:disabled,
.hs-button:disabled,
input[type='submit']:disabled {
  opacity: .5;
  pointer-events: none;
}

button.-gray,
.btn.-gray,
.button.-gray,
.hs-button.-gray,
input[type='submit'].-gray {
  background-color: #606161;
}

button.-gray:active, button.-gray:focus, button.-gray:hover,
.btn.-gray:active,
.btn.-gray:focus,
.btn.-gray:hover,
.button.-gray:active,
.button.-gray:focus,
.button.-gray:hover,
.hs-button.-gray:active,
.hs-button.-gray:focus,
.hs-button.-gray:hover,
input[type='submit'].-gray:active,
input[type='submit'].-gray:focus,
input[type='submit'].-gray:hover {
  background: #535353;
}

button.-arrow,
.btn.-arrow,
.button.-arrow,
.hs-button.-arrow,
input[type='submit'].-arrow {
  padding: 20px 38px 20px 20px;
}

button.-arrow::after,
.btn.-arrow::after,
.button.-arrow::after,
.hs-button.-arrow::after,
input[type='submit'].-arrow::after {
  content: "";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: rotate(-90deg);
  right: 10px;
  font-size: 18px;
  font-size: 1.125rem;
  margin-top: -9px;
}

.contact {
  padding: 45px 20px;
  background-image: url("../images/bg-swoops-left.jpg");
  background-size: cover;
}

@media (min-width: 900px) {
  .contact {
    padding: 80px 20px 60px;
  }
}

.contact-inner {
  max-width: 1280px;
  margin: 0 auto;
}

@media (min-width: 900px) {
  .contact-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.contact-content {
  margin-bottom: 30px;
}

@media (min-width: 900px) {
  .contact-content {
    padding-top: 30px;
    width: 33%;
  }
}

.contact-content h3,
.contact-content p {
  color: #fff;
}

.contact-form {
  padding: 30px 20px;
  border-top: 4px solid #659f8a;
  background: #fff;
  box-shadow: 0 5px 10px -1px rgba(0, 0, 0, 0.15);
}

@media (min-width: 900px) {
  .contact-form {
    width: 62%;
    padding: 30px 40px;
  }
}

@media (min-width: 1300px) {
  .contact-form {
    padding: 40px 60px;
  }
}

.contact-form .hbspt-form {
  margin-right: -20px;
}

.contact-form .hbspt-form .hs-form-required {
  color: #659f8a;
}

.contact-form .hbspt-form .form-columns-1,
.contact-form .hbspt-form .form-columns-2 {
  width: 100%;
  max-width: none;
}

.contact-form .hbspt-form .input {
  margin-right: 20px !important;
}

.contact-form .hbspt-form .hs-input {
  width: 100% !important;
  margin-right: 10px !important;
}

.contact-form .hbspt-form .hs-error-msgs {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contact-form .hbspt-form .hs-error-msgs li {
  color: #fff;
  background: #659f8a;
  padding: 2px 4px;
  font-size: 14px;
  font-size: 0.875rem;
  margin-top: -15px;
  margin-right: 20px;
}

.contact-form .hbspt-form .hs-error-msgs li label {
  margin: 0;
  padding: 0;
  font-weight: 500;
}

@media (max-width: 399px) {
  .contact-form .hbspt-form .hs_recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }
}

.contact-form .hbspt-form .actions {
  margin-top: 20px;
}

.accordion-wrapper {
  max-width: 1010px;
  padding: 0 20px;
  margin: 0 auto;
}

@media (min-width: 1100px) {
  .accordion-wrapper {
    padding: 0;
  }
}

.accordion-wrapper .section-title {
  color: #000;
}

.accordion {
  margin: 0;
  padding: 0;
  list-style: none;
}

.accordion li {
  list-style: none;
  margin-bottom: 4px;
}

.accordion-trigger {
  font-size: 18px;
  font-size: 1.125rem;
}

.accordion-trigger > a {
  position: relative;
  padding: 14px 0 0 32px;
  text-decoration: none;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
}

.accordion-trigger > a::before {
  content: "";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 13px;
  left: 0;
  width: 20px;
  height: 20px;
  color: #6c6d6d;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  transition: 0.15s ease-in-out;
  transform: rotate(-90deg);
  border: 2px solid #6c6d6d;
  border-radius: 50%;
}

.accordion-trigger > a:hover::before, .accordion-trigger > a:focus::before {
  color: #6c6d6d;
}

.accordion-trigger.-active > a {
  color: #005695;
}

.accordion-trigger.-active > a::before {
  transform: rotate(0);
  color: #60b3e3;
  border: 2px solid #60b3e3;
}

.accordion-content {
  display: none;
  padding: 10px 0 0 32px;
  max-width: 767px;
}

.accordion-content > p {
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
}

.js-hero-scroll-link {
  display: block;
  width: 80px;
  height: 40px;
  border-radius: 80px 80px 0 0;
  background: #fff;
  backface-visibility: hidden;
  perspective: 1000px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -40px;
  text-align: center;
  line-height: 70px;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
}

.js-hero-scroll-link::before {
  content: "";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #00497e;
  font-size: 24px;
  font-size: 1.5rem;
  transition: 0.15s ease-in-out;
  animation: bounce 1.5s 5;
}

.js-hero-scroll-link:hover, .js-hero-scroll-link:focus {
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.2);
}

@media (min-width: 1800px) {
  .js-hero-scroll-link:hover, .js-hero-scroll-link:focus {
    box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.05);
  }
}

.hero {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #005695;
  color: #fff;
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  position: relative;
  overflow: hidden;
  padding: 170px 20px 70px;
  text-align: center;
}

@media (min-width: 768px) {
  .hero {
    min-height: 475px;
    text-align: left;
  }
}

.hero-ellipse {
  position: absolute;
  top: -100%;
  left: -18%;
  width: 80%;
  max-width: none;
}

@media (min-width: 768px) {
  .hero-ellipse {
    top: -80%;
    left: -18%;
  }
}

@media (min-width: 1200px) {
  .hero-ellipse {
    top: -110%;
    width: 968px;
  }
}

.hero-inner {
  flex: 1 1 auto;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
}

@media (min-width: 768px) {
  .hero-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.hero-content {
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .hero-content {
    width: 66%;
    max-width: 555px;
    margin-bottom: 0;
  }
}

@media (min-width: 900px) {
  .hero-content {
    width: 60%;
  }
}

@media (min-width: 1000px) {
  .hero-content {
    width: 70%;
  }
}

.hero-title {
  font-size: 30px;
  font-size: 1.875rem;
  color: #fff;
}

@media (min-width: 768px) {
  .hero-title {
    font-size: 34px;
    font-size: 2.125rem;
  }
}

@media (min-width: 1000px) {
  .hero-title {
    font-size: 40px;
    font-size: 2.5rem;
  }
}

.hero-title strong {
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 900;
}

@media (min-width: 1000px) {
  .hero-title strong {
    font-size: 50px;
    font-size: 3.125rem;
  }
}

.hero-login {
  padding: 20px;
  background: #004577;
  text-align: left;
  flex: 0 1 auto;
}

@media (min-width: 768px) {
  .hero-login {
    width: 33%;
    margin-left: 20px;
  }
}

@media (min-width: 900px) {
  .hero-login {
    width: 40%;
    padding: 30px 40px;
  }
}

@media (min-width: 1000px) {
  .hero-login {
    width: 30%;
  }
}

@media (min-width: 1100px) {
  .hero-login {
    margin: 0 50px;
  }
}

@media (min-width: 1300px) {
  .hero-login {
    margin: 0 100px;
  }
}

.hero-login .login-title {
  position: relative;
  color: #fff;
  margin-bottom: 10px;
  padding-left: 28px;
  font-size: 18px;
  font-size: 1.125rem;
}

.hero-login .login-title::before {
  content: "";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 3px;
  left: 0;
  opacity: .5;
}

.hero-login .button {
  width: 100%;
  text-align: left;
  line-height: 1.5;
}

.hero-login .button:last-child {
  margin-top: 10px;
}

.images-grid {
  padding: 45px 20px;
  background: #004577;
}

@media (min-width: 900px) {
  .images-grid {
    padding: 60px 20px;
  }
}

.images-grid-inner {
  max-width: 1280px;
  margin: 0 auto;
}

.images-grid-intro {
  text-align: center;
  max-width: 1080px;
  margin: 0 auto;
  color: #fff;
}

.images-grid-intro h3 {
  color: #fff;
}

.insight-teaser {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-top: 4px solid #659f8a;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 35px 25px 30px;
}

@media (min-width: 768px) {
  .insight-teaser {
    padding: 35px 35px 30px;
  }
}

@media (min-width: 1200px) {
  .insight-teaser {
    min-height: 240px;
  }
}

.insight-teaser:hover, .insight-teaser:focus {
  background: #659f8a;
  border-color: #659f8a;
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.2);
}

.insight-teaser:hover .insight-teaser-title,
.insight-teaser:hover .insight-teaser-date,
.insight-teaser:hover .insight-teaser-author, .insight-teaser:focus .insight-teaser-title,
.insight-teaser:focus .insight-teaser-date,
.insight-teaser:focus .insight-teaser-author {
  color: #fff;
}

.insight-teaser:hover .insight-teaser-date, .insight-teaser:focus .insight-teaser-date {
  opacity: .8;
}

.insight-teaser-top {
  flex: 1 1 auto;
}

.insight-teaser-title {
  color: #1d1d1d;
  font-family: "Merriweather", serif;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  letter-spacing: .01em;
  line-height: 1.53;
  margin: 0 0 10px;
  transition: 0.15s ease-in-out;
}

@media (min-width: 600px) {
  .insight-teaser-title {
    font-size: 16px;
    font-size: 1rem;
  }
}

.insight-teaser-date {
  color: #6c6d6d;
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 11px;
  font-size: 0.6875rem;
  letter-spacing: -.01em;
  margin-bottom: 30px;
  transition: 0.15s ease-in-out;
}

@media (min-width: 600px) {
  .insight-teaser-date {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

.insight-teaser-author {
  flex: none;
  display: flex;
  align-items: center;
  color: #6c6d6d;
}

.insight-teaser-author-image {
  width: 40px;
  border-radius: 50%;
  margin-right: 6px;
}

.insight-teaser-author-byline {
  flex: 1 1 0;
  font-size: 13px;
  font-size: 0.8125rem;
  letter-spacing: .01em;
}

.insight-teaser-author-byline strong {
  font-family: "Merriweather", serif;
  font-weight: 700;
}

.insights-grid {
  padding: 60px 20px;
  margin: 60px 0 20px;
  background-image: url("../images/bg-swoops-right.jpg");
  background-size: cover;
  background-position: center;
}

@media (min-width: 900px) {
  .insights-grid {
    margin: 80px 0 20px;
  }
}

.insights-grid-inner {
  max-width: 1280px;
  margin: 0 auto;
}

.insights-grid-top {
  text-align: center;
  margin-bottom: 30px;
}

.insights-grid-top h3 {
  color: #fff;
}

.insights-grid-items {
  margin-top: 25px;
}

.insights-grid-items .insight-teaser {
  margin-bottom: 30px;
}

@media (min-width: 600px) {
  .insights-grid-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .insights-grid-items > * {
    width: calc(50% - 30px);
    margin: 0 15px 30px;
  }
}

@media (min-width: 1200px) {
  .insights-grid-items > * {
    width: auto;
    flex: 1 1 0;
  }
}

.insights-grid-all-link {
  text-align: right;
  margin-top: 10px;
}

@media (min-width: 600px) {
  .insights-grid-all-link {
    margin-top: -10px;
  }
}

.insights-grid-all-link a {
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 900;
  letter-spacing: .07em;
  text-transform: uppercase;
  color: #fff;
}

.insights-grid-all-link a::after {
  content: "";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-size: 1rem;
  margin-left: 4px;
  position: relative;
  top: 2px;
  left: 0;
  transform: rotate(-90deg);
  transition: 0.15s ease-in-out;
}

.insights-grid-all-link a:focus, .insights-grid-all-link a:hover {
  opacity: .9;
}

.insights-grid-all-link a:focus::after, .insights-grid-all-link a:hover::after {
  left: 3px;
}

.location {
  width: 100%;
  padding: 30px 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
}

@media (min-width: 600px) {
  .location {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

.location-title {
  width: 100%;
  margin-bottom: 10px;
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: 400;
  color: #000;
}

.location-details, .location-contact {
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
}

.location-details a, .location-contact a {
  font-weight: 700;
  border-bottom: 1px solid transparent;
  line-height: 1.3;
}

.location-details a:hover, .location-details a:focus, .location-contact a:hover, .location-contact a:focus {
  border-color: #00497e;
}

.location-details {
  letter-spacing: -.01em;
  margin-bottom: 25px;
}

@media (min-width: 600px) {
  .location-details {
    width: 50%;
  }
}

@media (min-width: 900px) {
  .location-details {
    flex: 1 1 0;
    max-width: 430px;
    padding-left: 0;
    padding-right: 20px;
    margin-bottom: 0;
  }
}

.location-details-address {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
}

.location-details-address::before {
  content: "";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  text-align: center;
  color: #999;
}

.location-details-directions {
  display: flex;
  align-items: center;
}

.location-details-directions-item {
  position: relative;
  padding-left: 25px;
}

.location-details-directions-item::before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  text-align: center;
  color: #999;
}

.location-details-directions-item:first-child::before {
  content: "";
}

.location-details-directions-separator {
  flex: none;
  display: inline-block;
  color: #999;
  font-size: 16px;
  font-size: 1rem;
  margin: 0 10px 0 8px;
}

.location-contact {
  letter-spacing: .02em;
}

@media (min-width: 600px) {
  .location-contact {
    width: 100%;
  }
}

@media (min-width: 900px) {
  .location-contact {
    flex: 1 1 0;
    max-width: 330px;
    padding-left: 0;
  }
}

.location-contact-hours, .location-contact-phone {
  position: relative;
  padding-left: 25px;
}

.location-contact-hours::before, .location-contact-phone::before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 2px;
  left: 0;
  width: 25px;
  text-align: center;
  color: #999;
}

.location-contact-phone::before {
  content: "";
}

.location-contact-hours {
  margin-bottom: 10px;
}

.location-contact-hours::before {
  content: "";
}

.location-tabs-top {
  text-align: center;
  padding: 20px;
}

.js-location-tabs {
  display: flex;
  position: relative;
  justify-content: center;
  list-style: none;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 0 4px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.js-location-tabs li {
  display: block;
  margin: 0 5px;
  padding: 14px 5px;
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #6c6d6d;
  letter-spacing: .03em;
  font-weight: 900;
  font-size: 12px;
  font-size: 0.75rem;
  cursor: pointer;
  position: relative;
  top: 4px;
  border-bottom: 4px solid transparent;
  transition: 0.15s ease-in-out;
}

@media (min-width: 400px) {
  .js-location-tabs li {
    padding: 14px 9px;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.js-location-tabs li:hover, .js-location-tabs li:focus, .js-location-tabs li:active {
  border-color: #0091d0;
  color: #00497e;
}

.js-location-tabs li.-active {
  border-color: #0091d0;
  color: #00497e;
}

.tab-content {
  position: absolute;
  top: -99999px;
  opacity: 0;
  visibility: hidden;
  margin: 0 auto;
  max-width: 1280px;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  padding: 20px 20px 45px;
  transition: 0.3s ease-in-out;
}

@media (min-width: 1310px) {
  .tab-content {
    padding: 20px 0 80px;
  }
}

.tab-content.-active {
  position: static;
  opacity: 1;
  visibility: visible;
}

@media (min-width: 1100px) {
  .tab-content.-active {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

@media (min-width: 1100px) {
  .tab-content .location {
    width: 48%;
  }
}

@media (min-width: 1300px) {
  .tab-content .location {
    width: 46%;
  }
}

.tab-content .all-locations-link {
  width: 100%;
  margin-top: 30px;
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 900;
  letter-spacing: .07em;
  text-transform: uppercase;
}

.tab-content .all-locations-link::after {
  content: "";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-size: 1rem;
  margin-left: 4px;
  position: relative;
  top: 2px;
  left: 0;
  transform: rotate(-90deg);
  transition: 0.15s ease-in-out;
}

.tab-content .all-locations-link:focus, .tab-content .all-locations-link:hover {
  opacity: .9;
}

.tab-content .all-locations-link:focus::after, .tab-content .all-locations-link:hover::after {
  left: 3px;
}

.mobile-menu-trigger {
  display: block;
  position: absolute;
  width: 42px;
  height: 42px;
  color: #fff;
  text-align: center;
  top: 20px;
  right: -10px;
  transition: 0.15s ease-in-out;
  z-index: 500;
}

@media (min-width: 768px) {
  .mobile-menu-trigger {
    display: none;
  }
}

.mobile-menu-trigger::before {
  content: "";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 30px;
  font-size: 1.875rem;
}

.mobile-menu-trigger:hover, .mobile-menu-trigger:focus {
  color: #fff;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  margin: 0 -250px 0 0;
  transition: 0.15s ease-in-out;
  z-index: 11;
}

@media (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
}

.mobile-menu.-open {
  margin: 0;
}

.mobile-menu ul {
  width: 250px;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  background: #002744;
  overflow: hidden;
}

.mobile-menu li {
  margin: 0;
}

.mobile-menu a {
  color: #fff;
  font-family: "Merriweather", serif;
  font-size: 14px;
  font-size: 0.875rem;
  text-decoration: none;
  display: block;
  padding: 27.5px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.mobile-menu a:hover {
  background: rgba(0, 0, 0, 0.9);
}

.resources {
  max-width: 1010px;
  padding: 0 20px;
  margin: 0 auto;
}

@media (min-width: 1100px) {
  .resources {
    padding: 0;
  }
}

.resources .section-title {
  color: #000;
}

.site-footer {
  padding: 34px 20px;
  background: #004577;
  color: #c0d2e1;
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  text-align: center;
}

@media (min-width: 768px) {
  .site-footer {
    text-align: left;
  }
}

.site-footer-inner {
  margin: 0 auto;
  padding-bottom: 20px;
  max-width: 1280px;
}

@media (min-width: 768px) {
  .site-footer-inner {
    display: flex;
    justify-content: space-between;
  }
}

.site-footer-links {
  padding-bottom: 3px;
}

.site-footer-links::after {
  content: '';
  display: block;
  clear: both;
}

.site-footer-links-item {
  float: left;
  padding-left: 11px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.site-footer-links-item:first-child {
  padding: 0;
  border: 0;
}

.site-footer-links a {
  color: #fff;
  margin: 0 5px;
}

@media (min-width: 768px) {
  .site-footer-links a {
    margin: 0 10px 0 0;
  }
}

.site-footer-logos {
  margin-top: 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .site-footer-logos {
    width: 50%;
    margin: 0;
    text-align: right;
  }
}

.site-footer-logos img {
  display: inline;
  width: auto;
  height: 34px;
  margin: 0 7px;
}

@media (min-width: 768px) {
  .site-footer-logos img {
    margin: 0 0 0 14px;
  }
}

.site-footer-assistance {
  margin: 0 auto;
  padding: 14px 0 0;
  max-width: 1280px;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.site-footer-assistance a {
  color: #fff;
}

.site-footer a {
  border-bottom: 1px solid transparent;
}

.site-footer a:hover, .site-footer a:focus {
  border-color: #fff;
}

.site-header {
  position: relative;
  z-index: 10;
  background: rgba(0, 69, 119, 0.57);
  padding: 0 20px;
  margin-bottom: -105px;
}

@media (min-width: 1000px) {
  .site-header {
    margin-bottom: -114px;
  }
}

.site-header .site-header-inner {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-header-left {
  flex: none;
  margin-right: 30px;
  padding: 20px 0;
}

@media (min-width: 768px) {
  .site-header-left {
    padding: 0;
  }
}

.site-header-left .site-header-logo {
  width: 150px;
  display: block;
}

@media (min-width: 900px) {
  .site-header-left .site-header-logo {
    width: 160px;
  }
}

.site-header-left .site-header-logo img {
  width: 100%;
}

.site-header-right {
  flex: none;
}

@media (min-width: 768px) {
  .site-header-right {
    padding-top: 30px;
  }
}

.primary-nav {
  display: none;
}

@media (min-width: 768px) {
  .primary-nav {
    display: block;
  }
}

.primary-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.primary-nav ul li {
  flex: none;
}

.primary-nav ul li + li {
  margin-left: 24px;
}

.primary-nav ul li a {
  color: #fff;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: .03em;
  padding-bottom: 26px;
  border-bottom: 4px solid transparent;
}

.primary-nav ul li a:focus, .primary-nav ul li a:hover {
  border-color: #f6cc66;
}

.staggered-three-up {
  padding: 0 20px;
  margin: 45px 0;
}

@media (min-width: 900px) {
  .staggered-three-up {
    margin: 60px 0;
  }
}

.staggered-three-up-inner {
  max-width: 1280px;
  margin: 0 auto;
}

@media (min-width: 900px) {
  .staggered-three-up-inner {
    display: flex;
  }
}

.staggered-three-up-block {
  position: relative;
  text-align: center;
  padding: 30px 20px;
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .staggered-three-up-block {
    padding: 50px;
  }
}

@media (min-width: 900px) {
  .staggered-three-up-block {
    flex: 1 1 0;
    margin: 0;
  }
}

.staggered-three-up-block::before {
  content: '';
  position: absolute;
  width: 150%;
  height: 300%;
  border-radius: 100%;
  background: lime;
  backface-visibility: hidden;
}

.staggered-three-up-block-inner {
  position: relative;
}

.staggered-three-up-block-icon, .staggered-three-up-block-title, .staggered-three-up-block-text {
  color: #fff;
}

.staggered-three-up-block-icon {
  font-size: 120px;
  font-size: 7.5rem;
}

.staggered-three-up-block-title {
  margin-bottom: 15px;
}

.staggered-three-up-block-text {
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  letter-spacing: .01em;
  line-height: 1.6;
  margin-bottom: 15px;
}

.staggered-three-up-block-link {
  width: 100%;
  margin-top: 30px;
  font-family: "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 900;
  letter-spacing: .07em;
  text-transform: uppercase;
  color: #fff;
}

.staggered-three-up-block-link::after {
  content: "";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-size: 1rem;
  margin-left: 4px;
  position: relative;
  top: 2px;
  left: 0;
  transform: rotate(-90deg);
  transition: 0.15s ease-in-out;
}

.staggered-three-up-block-link:focus, .staggered-three-up-block-link:hover {
  color: #fff;
  opacity: .9;
}

.staggered-three-up-block-link:focus::after, .staggered-three-up-block-link:hover::after {
  left: 3px;
}

.staggered-three-up-block:first-child {
  background-color: #005695;
}

@media (min-width: 900px) {
  .staggered-three-up-block:first-child {
    margin-top: 60px;
  }
}

.staggered-three-up-block:first-child::before {
  top: -180%;
  left: 3%;
  opacity: .5;
  background: #005fa4;
}

.staggered-three-up-block:nth-child(2) {
  background-color: #659f8a;
  z-index: 10;
}

.staggered-three-up-block:nth-child(2)::before {
  top: -180%;
  left: -82%;
  background: #5c947f;
}

@media (min-width: 900px) {
  .staggered-three-up-block:nth-child(2) {
    margin-bottom: 60px;
  }
}

.staggered-three-up-block:last-child {
  background-color: #6c6d6d;
}

@media (min-width: 900px) {
  .staggered-three-up-block:last-child {
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

.staggered-three-up-block:last-child::before {
  top: -170%;
  left: -50%;
  background: #747575;
}

.text-block {
  padding: 0 20px;
  margin: 45px 0;
  text-align: center;
}

.text-block-inner {
  max-width: 1010px;
  margin: 0 auto;
}
