.contact {
  padding: 45px 20px;
  background-image: url('../images/bg-swoops-left.jpg');
  background-size: cover;

  @include from(9) {
    padding: 80px 20px 60px;
  }

  &-inner {
    max-width: 1280px;
    margin: 0 auto;

    @include from(9) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &-content {
    margin-bottom: 30px;

    @include from(9) {
      padding-top: 30px;
      width: 33%;
    }

    h3,
    p {
      color: primary-color(white);
    }
  }

  &-form {
    padding: 30px 20px;
    border-top: 4px solid primary-color(green);
    background: primary-color(white);
    box-shadow: 0 5px 10px -1px rgba(0, 0, 0, .15);

    @include from(9) {
      width: 62%;
      padding: 30px 40px;
    }

    @include from(13) {
      padding: 40px 60px;
    }

    .hbspt-form {
      margin-right: -20px;

      .hs-form-required {
        color: primary-color(green);
      }

      .form-columns-1,
      .form-columns-2 {
        width: 100%;
        max-width: none;
      }

      .input {
        // sass-lint:disable-block no-important
        margin-right: 20px !important;
      }

      .hs-input {
        // sass-lint:disable-block no-important
        width: 100% !important;
        margin-right: 10px !important;
      }

      .hs-error-msgs {
        @include kill-bullets;

        li {
          color: primary-color(white);
          background: primary-color(green);
          padding: 2px 4px;
          @include rem(font-size, 14px);

          margin-top: -15px;
          margin-right: 20px;

          label {
            margin: 0;
            padding: 0;
            @include font-weight(medium);
          }
        }
      }

      .hs_recaptcha { // sass-lint:disable-line class-name-format

        @include to(3) {
          transform: scale(.77);
          transform-origin: 0 0;
        }
      }

      .actions {
        margin-top: 20px;
      }
    }
  }
}
