.site-footer {
  padding: 34px 20px;
  background: primary-color(navy, shade1);
  color: #c0d2e1;
  font-family: $header-font;
  @include rem(font-size, 12px);
  text-align: center;

  @include from(7) {
    text-align: left;
  }

  &-inner {
    margin: 0 auto;
    padding-bottom: 20px;
    max-width: 1280px;

    @include from(7) {
      display: flex;
      justify-content: space-between;
    }
  }

  &-links {
    @include clearfix-micro;
    padding-bottom: 3px;

    &-item {
      float: left;
      padding-left: 11px;
      border-left: 1px solid rgba(primary-color(white), .3);

      &:first-child {
        padding: 0;
        border: 0;
      }
    }

    a {
      color: primary-color(white);
      margin: 0 5px;

      @include from(7) {
        margin: 0 10px 0 0;
      }
    }
  }

  &-logos {
    margin-top: 20px;
    text-align: center;

    @include from(7) {
      width: 50%;
      margin: 0;
      text-align: right;
    }

    img {
      display: inline;
      width: auto;
      height: 34px;
      margin: 0 7px;

      @include from(7) {
        margin: 0 0 0 14px;
      }
    }
  }

  &-assistance {
    margin: 0 auto;
    padding: 14px 0 0;
    max-width: 1280px;
    width: 100%;
    border-top: 1px solid rgba(primary-color(white), .2);

    a {
      color: primary-color(white);
    }
  }

  a {
    border-bottom: 1px solid transparent;

    &:hover,
    &:focus {
      border-color: primary-color(white);
    }
  }
}
