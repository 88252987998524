.insight-teaser {
  display: flex;
  flex-direction: column;
  background: primary-color(white);
  border-top: 4px solid primary-color(green);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
  padding: 35px 25px 30px;

  @include from(7) {
    padding: 35px 35px 30px;
  }

  @include from(12) {
    min-height: 240px;
  }

  &:hover,
  &:focus {
    background: primary-color(green);
    border-color: primary-color(green);
    box-shadow: 0 12px 18px 0 rgba(0, 0, 0, .2);

    .insight-teaser-title,
    .insight-teaser-date,
    .insight-teaser-author {
      color: primary-color(white);
    }

    .insight-teaser-date {
      opacity: .8;
    }
  }

  &-top {
    flex: 1 1 auto;
  }

  &-title {
    color: #1d1d1d;
    font-family: $body-font;
    @include rem(font-size, 15px);
    @include font-weight(bold);
    letter-spacing: .01em;
    line-height: 1.53;
    margin: 0 0 10px;
    transition: $ts $te;

    @include from(6) {
      @include rem(font-size, 16px);
    }
  }

  &-date {
    color: primary-color(gray);
    font-family: $header-font;
    @include rem(font-size, 11px);
    letter-spacing: -.01em;
    margin-bottom: 30px;
    transition: $ts $te;

    @include from(6) {
      @include rem(font-size, 13px);
    }
  }

  &-author {
    flex: none;
    display: flex;
    align-items: center;
    color: primary-color(gray);

    &-image {
      width: 40px;
      border-radius: 50%;
      margin-right: 6px;
    }

    &-byline {
      flex: 1 1 0;
      @include rem(font-size, 13px);
      letter-spacing: .01em;

      strong {
        font-family: $body-font;
        @include font-weight(bold);
      }
    }
  }
}
