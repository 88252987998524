.js-hero-scroll-link {
  display: block;
  width: 80px;
  height: 40px;
  border-radius: 80px 80px 0 0;
  background: primary-color(white);
  backface-visibility: hidden;
  perspective: 1000px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -40px;
  text-align: center;
  line-height: 70px;

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }

    40% {
      transform: translateY(-6px);
    }

    60% {
      transform: translateY(-3px);
    }
  }

  @include icon(caret) {
    color: primary-color(navy);
    @include rem(font-size, 24px);
    transition: $ts $te;
    animation: bounce 1.5s 5;
  }

  &:hover,
  &:focus {
    box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, .2);

    @include from(18) {
      box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, .05);
    }
  }
}
